import React, { PropsWithChildren } from "react";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import { FHNextImage, ImageLoader } from "../FHNextImage";
import { useRouter } from "next/router";
import { Chip } from "../Chip";

const useStyles = makeStyles((theme) => ({
  root: () => ({
    position: "relative",
    zIndex: 0,
    display: "flex",
    flexDirection: "column",
    height: "180px",
    justifyContent: "center",
    alignItems: "center",
    boxShadow: "0px 1px 7px 0px rgba(0,0,0,0.2)",
    borderRadius: theme.spacing(1),
    [theme.breakpoints.up("md")]: {
      "&:hover": {
        textAlign: "center",
      },
    },
    "&:hover": {
      "& $background, & $description": {
        display: "none",
        textAlign: "center",
      },
      "& $title, & $subtitle": {
        textAlign: "center",
        color: theme.palette.background.paper,
      },
      "& $title": {
        fontFamily: theme.typography.h2.fontFamily,
        fontWeight: 600,
      },
    },
  }),
  card: () => ({
    display: "block",
    width: "100%",
    height: "100%",
    textDecoration: "none",
    zIndex: 2,
    position: "absolute",
    textAlign: "center",
    backgroundImage: "linear-gradient(rgba(0, 0, 0, 0) 30%, rgb(0, 0, 0) 80%)",
    borderRadius: theme.spacing(1),
    "&:hover": {
      backgroundImage:
        "linear-gradient(rgba(0, 0, 0, 0) 30%, rgb(5, 88, 69) 80%)",
    },
  }),
  cardInfo: () => ({
    position: "absolute",
    bottom: 10,
    color: `${theme.palette.common.white} !important`,
    "&:hover": {
      color: `${theme.palette.common.white} !important`,
    },
    zIndex: 3,
    textAlign: "center",
    width: "100%",
  }),
  title: () => ({
    fontSize: "16px !important",
    color: `${theme.palette.background.paper} !important`,
    textAlign: "center",
    fontWeight: 500,
    lineHeight: "22px",
  }),
  background: () => ({
    display: "none",
    height: "100%",
    width: "100%",
    backgroundColor: "rgba(255,255,255,0.75)",
    backdropFilter: "blur(8px) saturate(1)",
    zIndex: 2,
    position: "absolute",
    borderRadius: theme.spacing(1),
    textAlign: "center",
  }),
  subtitle: () => ({
    color: theme.palette.background.paper,
    marginBottom: theme.spacing(1),
    textAlign: "center",
  }),
  description: () => ({
    display: "none",
    textAlign: "center",
  }),
  backgroundImg: {
    position: "absolute",
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
    zIndex: 1,
    borderRadius: theme.spacing(1),
    overflow: "hidden",
  },
}));

export type TileProps = {
  backgroundImageUrl?: string;
  title?: string;
  chip?: string;
  link?: string;
  imageLoader?: ImageLoader;
};

export const TileSecondaryDesign: React.FC<TileProps> = ({
  backgroundImageUrl = "",
  title,
  chip,
  link,
  imageLoader,
}: PropsWithChildren<TileProps>) => {
  const history = useRouter();
  const classes = useStyles();

  const handleOnClick = () => {
    if (!!link) {
      history.push(link);
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.backgroundImg}>
        <FHNextImage
          src={backgroundImageUrl}
          blurDataURL={backgroundImageUrl}
          alt={title}
          loading="lazy"
          loader={imageLoader}
          layout="fill"
          objectFit="cover"
        />
      </div>
      <a className={classes.card} href={link} aria-label={title}>
        <div className={classes.cardInfo}>
          <Typography
            component="div"
            className={classes.title}
            onClick={handleOnClick}
          >
            {`${title}${!!link ? " >" : ""}`}
          </Typography>
        </div>
      </a>
      {chip && <Chip label={chip} pinned={true}></Chip>}
    </div>
  );
};
