import React, { PropsWithChildren } from "react";
import classnames from "classnames";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import { Variant } from "@mui/material/styles/createTypography";
import { Chip } from "../Chip";
import { FHNextImage, ImageLoader } from "../FHNextImage";
import { TileSecondaryDesign } from "./TileSecondaryDesign";

type TileStyleProps = {
  textAlignment: "bottom" | "center";
  responsive: boolean;
  titleShadow: boolean;
  hasDescription: boolean;
};

const useStyles = makeStyles((theme) => ({
  root: ({ hasDescription, textAlignment }: TileStyleProps) => ({
    position: "relative",
    zIndex: 0,
    display: "flex",
    flexDirection: "column",
    height: "180px",
    justifyContent: textAlignment === "bottom" ? "flex-end" : "center",
    alignItems: "center",
    boxShadow: "0px 1px 7px 0px rgba(0,0,0,0.2)",
    borderRadius: theme.spacing(1),

    [theme.breakpoints.up("md")]: {
      "&:hover": {
        filter: hasDescription ? "saturate(1)" : undefined,
        textAlign: "center",
      },
    },
    "&:hover": {
      "& $background, & $description": {
        display: hasDescription ? "block" : "none",
        textAlign: "center",
      },
      "& $title, & $subtitle": {
        textAlign: "center",
        color: hasDescription
          ? theme.palette.text.primary
          : theme.palette.background.paper,
      },
      "& $title": {
        textShadow: hasDescription
          ? "0px 1px 3px rgba(225,225,225,1)"
          : undefined,
      },
    },
  }),
  card: () => ({
    display: "block",
    width: "100%",
    height: "100%",
    textDecoration: "none",
    zIndex: 3,
    position: "absolute",
    textAlign: "center",
  }),
  cardInfo: ({ textAlignment }: TileStyleProps) => ({
    position: "relative",
    padding: textAlignment === "bottom" ? theme.spacing(2) : "",
    paddingBottom: textAlignment === "bottom" ? theme.spacing(1) : "",
    zIndex: 2,
    textAlign: "center",
    width: "100%",
  }),
  title: ({ titleShadow }: TileStyleProps) => ({
    fontSize: "18px",
    color: theme.palette.background.paper,
    textShadow: titleShadow ? "0px 1px 5px rgba(0,0,0,1)" : undefined,
    textAlign: "center",
    fontWeight: 500,
    lineHeight: "22px",
  }),
  background: () => ({
    display: "none",
    height: "100%",
    width: "100%",
    backgroundColor: "rgba(255,255,255,0.75)",
    backdropFilter: "blur(8px) saturate(1)",
    zIndex: 2,
    position: "absolute",
    borderRadius: theme.spacing(1),
    textAlign: "center",
  }),
  subtitle: ({ titleShadow }: TileStyleProps) => ({
    color: theme.palette.background.paper,
    marginBottom: theme.spacing(1),
    textAlign: "center",
    textShadow: titleShadow ? "0px 1px 5px rgba(0,0,0,1)" : undefined,
  }),
  description: () => ({
    display: "none",
    textAlign: "center",
  }),
  backgroundImg: {
    position: "absolute",
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
    zIndex: 1,
    borderRadius: theme.spacing(1),
    overflow: "hidden",
  },
}));

export type TileProps = {
  backgroundImageUrl?: string;
  title?: string;
  subtitle?: string;
  titleClass?: string;
  titleShadow?: boolean;
  chip?: string;
  link?: string;
  textAlignment?: "bottom" | "center";
  responsive: boolean;
  className?: string;
  textVariant?: Variant;
  description?: string;
  imageLoader?: ImageLoader;
  useSecondaryDesign?: boolean;
};

export const Tile: React.FC<TileProps> = ({
  backgroundImageUrl = "",
  title,
  subtitle,
  chip,
  link,
  titleShadow = true,
  titleClass = "",
  textAlignment = "center",
  responsive = true,
  className = "",
  textVariant = "h6",
  description,
  imageLoader,
  useSecondaryDesign = false,
}: PropsWithChildren<TileProps>) => {
  const classes = useStyles({
    textAlignment,
    responsive,
    titleShadow,
    hasDescription: !!description,
  });

  return (
    <>
      {useSecondaryDesign ? (
        <TileSecondaryDesign
          backgroundImageUrl={backgroundImageUrl}
          chip={chip}
          imageLoader={imageLoader}
          link={link}
          title={title}
        />
      ) : (
        <div className={classes.root}>
          <div className={classes.backgroundImg}>
            <FHNextImage
              src={backgroundImageUrl}
              blurDataURL={backgroundImageUrl}
              alt={title !== " " ? title : subtitle}
              loading="lazy"
              loader={imageLoader}
              layout="fill"
              objectFit="cover"
            />
          </div>
          <a
            className={[classes.card, className].join(" ")}
            href={link}
            aria-label={title !== " " ? title : subtitle}
          />
          {chip && <Chip label={chip} pinned={true}></Chip>}
          <div className={classes.background} />
          <div className={classes.cardInfo}>
            <Typography
              variant={textVariant}
              component="div"
              className={classnames(classes.title, titleClass)}
            >
              {title}
            </Typography>
            <Typography variant="body2" className={classes.subtitle}>
              {subtitle}
            </Typography>
            <Typography variant="body2" className={classes.description}>
              {description}
            </Typography>
          </div>
        </div>
      )}
    </>
  );
};
