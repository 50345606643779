import React, { PropsWithChildren, useEffect, useState } from "react";
import { TileSecondaryDesign } from "../Tile/TileSecondaryDesign";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Hidden, useMediaQuery, useTheme } from "@mui/material";
import FHLink from "../FHLink";
import ExpandIcon from "@mui/icons-material/ExpandMore";

export type TileContainerProps = {
  cards?: Array<typeof TileSecondaryDesign | JSX.Element>;
  title?: string;
  subtitle?: string;
  linkText?: string;
  linkUrl?: string;
};

export const TileContainerSecondaryDesign: React.FC<TileContainerProps> = ({
  cards = [],
  title = "",
  subtitle,
  linkText,
  linkUrl = "",
}: PropsWithChildren<TileContainerProps>) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [showAllClicked, setShowAllClicked] = useState<boolean>(!isMobile);
  const [shownCards, setShownCards] = useState<
    Array<typeof TileSecondaryDesign | JSX.Element>
  >(showAllClicked ? cards : cards.slice(0, 5));

  useEffect(() => {
    showAllClicked && setShownCards(cards);
  }, [showAllClicked]);

  return (
    <div data-testid={"tileContainerRoot"}>
      <Grid
        container
        spacing={isMobile ? 1 : 1.5}
        paddingBottom={isMobile ? 2 : 1.5}
      >
        {!!title && (
          <Grid item xs>
            <Typography variant="h2">{title}</Typography>
          </Grid>
        )}
        {!isMobile && (
          <Grid item>
            <FHLink
              title={linkText}
              URL={linkUrl}
              color="secondary"
              hasDefaultIcon
              isBold
            />
          </Grid>
        )}
        {!!subtitle && (
          <Grid item xs={12}>
            <Typography> {subtitle}</Typography>
          </Grid>
        )}
      </Grid>
      <Hidden smUp>
        <Grid container spacing={1} direction="row" justifyContent="center">
          {shownCards.map((card, i) => (
            <Grid key={i} item xs={12}>
              <>{card}</>
            </Grid>
          ))}
          {!showAllClicked && (
            <Grid container paddingTop={2} justifyContent="center">
              <Grid item>
                <FHLink
                  title="Show more"
                  overrideColour="charcoal"
                  isBold
                  onLinkClick={() => setShowAllClicked(true)}
                  customIcon={<ExpandIcon fontSize="large" />}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      </Hidden>
      {!isMobile ? (
        <Grid container spacing={2} justifyContent="center">
          {cards.map((card, i) => (
            <Grid key={i} item xs={6} sm={4} md={3} lg={2.3}>
              <>{card}</>
            </Grid>
          ))}
        </Grid>
      ) : (
        <Grid container alignItems="center" justifyContent="center" padding={2}>
          <Grid item>
            <FHLink
              title={linkText}
              URL={linkUrl}
              color="secondary"
              hasDefaultIcon
              isBold
            />
          </Grid>
        </Grid>
      )}
    </div>
  );
};
