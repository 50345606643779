import React from "react";
import { makeStyles } from "@mui/styles";
import { withContent } from "../../utils/TemplateWithContent";
import { TitleVariant } from "../../components/SemanticTheme/Enums";
import { RichContentArea } from "../../components/SemanticTheme/RichContentArea";
import { makeHtml, processRichText } from "../../components/Utils";
import { FHInlineSvg } from "../../components/SemanticTheme/FHInlineSvg";
import { DescriptionCard } from "../../components/SemanticTheme/DescriptionCard";
import { DescriptionCards } from "../../components/SemanticTheme/DescriptionCards";
import { ThemeProvider } from "@mui/material/styles";
import SemanticTheme from "../../themes/SemanticTheme";
import { makeContentItemAbTestIdAttribute } from "../../utils/AbTestIdAndPaddingContainer";
import { DescriptionCardContainerEntity } from "src/graphql/generated-strapi/types";

const useStyles = makeStyles((theme) => ({
  root: {
    "& $cardContent": {
      "& > ul, & > ol": {
        display: "block",
        margin: "1em 0",
        paddingLeft: 0,
        listStylePosition: "inside",
      },
    },
  },
  cardContent: {
    "& span": {
      marginLeft: theme.spacing(-0.6),
    },
  },
}));

type DescriptionCardContainerProps = {
  additionalProps?: { [key: string]: string | boolean };
} & DescriptionCardContainerEntity;

const DescriptionCardContainerComponent: React.FC<
  DescriptionCardContainerProps
> = (props): JSX.Element => {
  const classes = useStyles();
  const descriptionCardsContent = props?.attributes?.cards?.data;
  const cardsIconColourOverride = props?.attributes?.CardsIconColourOverride
    ?.data?.attributes?.ColorCode as string;

  const descriptionCards = descriptionCardsContent.map((contentItem, i) => {
    return (
      <DescriptionCard
        showPaddingTop={
          (contentItem?.attributes?.showPaddingTop || false) as boolean
        }
        showPaddingBottom={
          (contentItem?.attributes?.showPaddingBottom || false) as boolean
        }
        key={i}
        icon={
          contentItem?.attributes?.Icon?.data ? (
            <FHInlineSvg
              src={
                contentItem?.attributes?.Icon?.data?.attributes?.url as string
              }
              size="24px"
              color={
                cardsIconColourOverride ??
                (contentItem?.attributes?.IconColour?.data?.attributes
                  ?.ColorCode as string)
              }
            />
          ) : undefined
        }
        title={contentItem?.attributes?.Title as string}
        titleVariant={
          (contentItem?.attributes?.TitleVariant as unknown as TitleVariant) ||
          TitleVariant.h2
        }
        description={
          <RichContentArea className={classes.root}>
            <div
              className={classes.cardContent}
              dangerouslySetInnerHTML={makeHtml(
                processRichText(contentItem?.attributes?.Description as string),
              )}
              {...makeContentItemAbTestIdAttribute(contentItem)}
            />
          </RichContentArea>
        }
      />
    );
  });

  return (
    <ThemeProvider theme={SemanticTheme}>
      <DescriptionCards
        cards={descriptionCards}
        showPaddingTopContainer={
          (props?.attributes?.showPaddingTop || false) as boolean
        }
        showPaddingBottomContainer={
          (props?.attributes?.showPaddingBottom || false) as boolean
        }
      />
    </ThemeProvider>
  );
};

const DescriptionCardContainerTemplate = (props) => {
  const content = props?.content?.descriptionCard?.data;
  const pageImgQuality = props?.pageImgQuality;
  const additionalProps = props?.additionalProps;
  return withContent(
    DescriptionCardContainerComponent,
    content,
    undefined,
    pageImgQuality,
    additionalProps,
  );
};

export default DescriptionCardContainerTemplate;
