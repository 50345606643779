import React from "react";
import { Container } from "@mui/material";
import { NavigationContainer } from "../../components/SemanticTheme/NavigationContainer";
import { SlidingContentCards } from "../../components/SemanticTheme/SlidingContentCard";
import CabinCardComponent from "./CabinCardTemplate";
import { withContent } from "../../utils/TemplateWithContent";
import { CabinsCardContainerEntity } from "src/graphql/generated-strapi/types";
import {
  maxImgWidth,
  updateAbsoluteLink,
  useGenerateResponsiveImageUrl,
} from "@components/Utils";
import TwoColumnCabinContainerCard from "@components/SereneTheme/TwoColumnCabinContainerCard";
import CustomContainer from "@components/SereneTheme/CustomContainer";

type CabinsCardContainerProps = {
  pageImgQuality?: number;
  additionalProps: { [key: string]: string | boolean };
  isGlentressLandingPageExp?: boolean;
} & CabinsCardContainerEntity;

const CabinsCardContainerComponent: React.FC<CabinsCardContainerProps> = (
  props: CabinsCardContainerProps,
): JSX.Element => {
  const isGlentressLandingPageExp = props?.isGlentressLandingPageExp;
  const content = props?.attributes;
  const title = content?.Title as string;
  const subtitle = content?.SubTitle as string;
  const callToActionText =
    (content?.PrimaryCallToActionText as string) ?? undefined;
  const callToActionUrl = content?.PrimaryCallToActionRef?.data?.attributes
    ?.PageRoute
    ? updateAbsoluteLink(
        content?.PrimaryCallToActionRef?.data?.attributes?.PageRoute,
      )
    : undefined;

  const maxWidthsGridImg = {
    xs: 650,
    sm: 650,
    md: 650,
    lg: 650,
    xl: 650,
  };

  const generateImageUrl = (
    url: string | undefined,
    maxWidths?: maxImgWidth,
  ) => {
    if (!!url) {
      return useGenerateResponsiveImageUrl(url, maxWidths, 85, true);
    }
  };

  const cardContentList: any = content?.cards?.data?.map((card) => {
    const imgUrl = card?.attributes?.Image?.data?.attributes?.url;
    const formatedImg = imgUrl
      ? generateImageUrl(imgUrl, maxWidthsGridImg)
      : "";
    const ctaURL =
      card?.attributes?.PrimaryCallToActionRef?.data?.attributes?.PageRoute;

    return {
      imgUrl: formatedImg,
      callToActionText: card?.attributes?.PrimaryCallToActionText,
      callToActionUrl: ctaURL ? updateAbsoluteLink(ctaURL) : undefined,
    };
  });

  return (
    <>
      {isGlentressLandingPageExp ? (
        <CustomContainer>
          <TwoColumnCabinContainerCard
            title={title}
            description={subtitle}
            cardContent={cardContentList}
            viewAllActionText={callToActionText}
            viewAllActionUrl={callToActionUrl}
          />
        </CustomContainer>
      ) : (
        <Container>
          <NavigationContainer
            ctaText={callToActionText}
            ctaURL={callToActionUrl}
            title={title}
            subTitle={subtitle}
            titleUrl={callToActionUrl}
          >
            <SlidingContentCards>
              {content?.cards?.data.map((cards, i) => {
                return (
                  <CabinCardComponent
                    {...cards?.attributes}
                    key={i}
                    pageImgQuality={props?.pageImgQuality}
                    containerImgQuality={content?.ImageQuality}
                  />
                );
              })}
            </SlidingContentCards>
          </NavigationContainer>
        </Container>
      )}
    </>
  );
};

const CabinsCardContainerTemplate = (props) => {
  const content =
    props?.content?.cabinCardContainer?.data?.[0] ||
    props?.content?.cabinCardContainer?.data;
  const pageImgQuality = props?.pageImgQuality;
  const additionalProps = props?.additionalProps;
  const isGlentressLandingPageExp = props?.isGlentressLandingPageExp;

  return withContent(
    CabinsCardContainerComponent,
    content,
    undefined,
    pageImgQuality,
    additionalProps,
    undefined,
    undefined,
    isGlentressLandingPageExp,
  );
};
export default CabinsCardContainerTemplate;
