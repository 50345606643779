import React, { PropsWithChildren } from "react";
import { makeStyles } from "@mui/styles";
import { defaultMaxWidths, useGenerateResponsiveImageUrl } from "../../Utils";
import { Swiper, SwiperSlide } from "swiper/react";
import { Scrollbar } from "swiper/modules";
import { Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/scrollbar";
import "swiper/css/navigation";
import { useMediaQuery, useTheme } from "@mui/material";
import StandardCardComponent from "../../../../src/pages/mapped-templates/StandardCardTemplate";
import { FHNextImage, ImageLoader } from "../FHNextImage";
import classnames from "classnames";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    "&:after": {
      right: 0,
      bottom: 100,
      top: 0,
      width: 154,
      position: "absolute",
      content: "''",
      zIndex: 9,
      background:
        "transparent linear-gradient(90deg, #FFFFFF00 0%, #FFFFFFF7 85%, #FFFFFF 100%) 0% 0% no-repeat padding-box",
      [theme.breakpoints.down("md")]: {
        width: 40,
        bottom: 60,
      },
    },
  },
  noFading: {
    "&:after": {
      display: "none",
    },
    "& $swiperSection": {
      paddingBottom: 10,
    },
    "& .swiper-button-prev": {
      display: "none !important",
    },
    "& .swiper-button-next": {
      display: "none !important",
    },
  },
  removeFading: {
    "&:after": {
      display: "none",
    },
  },
  swiperSection: {
    paddingBottom: 100,
    paddingLeft: 5,
    paddingRight: 5,
    [theme.breakpoints.down("md")]: {
      paddingBottom: 60,
    },
    "& .swiper-wrapper": {
      "& .swiper-slide": {
        height: "auto",
      },
    },
    "& .swiper-scrollbar": {
      bottom: 65,
      height: 10,
      left: 0,
      right: 0,
      width: "100%",
      backgroundColor: theme.palette.grey[50],
      "& .swiper-scrollbar-drag": {
        background: theme.palette.primary.dark,
      },
      [theme.breakpoints.down("md")]: {
        display: "none",
      },
    },
    "& .swiper-button-prev": {
      top: "auto",
      bottom: 0,
      marginTop: 0,
      padding: 0,
      width: "48%",
      justifyContent: "right",
      [theme.breakpoints.down("md")]: {
        width: "45%",
      },
      "&:before": {
        content: "''",
        background: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='MuiSvgIcon-root MuiSvgIcon-colorSecondary MuiSvgIcon-fontSizeMedium css-1hin0vp-MuiSvgIcon-root' focusable='false' aria-hidden='true' viewBox='0 0 24 24' data-testid='ChevronLeftIcon'%3E%3Cpath fill='%23055845' d='M15.41 7.41 14 6l-6 6 6 6 1.41-1.41L10.83 12z'%3E%3C/path%3E%3C/svg%3E")`,
        backgroundRepeat: "no-repeat",
        border: `1px solid ${theme.palette.primary.dark}`,
        width: 38,
        height: 38,
        borderRadius: "50%",
        order: 1,
      },
      "&:after": {
        fontSize: 16,
        fontFamily: theme.typography.body2.fontFamily,
        color: theme.palette.primary.dark,
        content: "'Previous'",
        padding: 0,
        paddingRight: 12,
      },
      "&:hover": {
        "&:before": {
          boxShadow: `inset 0 0 0 2px ${theme.palette.primary.dark}`,
        },
        "&:after": {
          fontFamily: theme.typography.h2.fontFamily,
        },
      },
      "& .swiper-button-disabled": {
        color: theme.palette.grey[50],
      },
    },
    "& .swiper-button-next": {
      top: "auto",
      bottom: 0,
      marginTop: 0,
      padding: 0,
      display: "flex",
      width: "48%",
      justifyContent: "left",
      [theme.breakpoints.down("md")]: {
        width: "45%",
      },
      "&:before": {
        background: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='MuiSvgIcon-root MuiSvgIcon-colorSecondary MuiSvgIcon-fontSizeMedium css-1yymlr5' focusable='false' aria-hidden='true' fill='%23055845' viewBox='0 0 24 24' data-testid='ChevronRightIcon'%3E%3Cpath d='M10 6 8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z'%3E%3C/path%3E%3C/svg%3E")`,
        backgroundRepeat: "no-repeat",
        content: "''",
        border: `1px solid ${theme.palette.primary.dark}`,
        width: 38,
        height: 38,
        borderRadius: "50%",
      },
      "&:after": {
        fontSize: 16,
        fontFamily: theme.typography.body2.fontFamily,
        color: theme.palette.primary.dark,
        content: "'Next'",
        padding: 0,
        paddingLeft: 12,
      },
      "&:hover": {
        "&:before": {
          boxShadow: `inset 0 0 0 2px ${theme.palette.primary.dark}`,
        },
        "&:after": {
          fontFamily: theme.typography.h2.fontFamily,
        },
      },
      "& .swiper-button-disabled": {
        color: theme.palette.grey[50],
      },
    },
  },
  slideImage: {
    width: "100%",
    minHeight: 143,
    paddingTop: "56.25%",
    position: "relative",
    borderRadius: theme.shape.borderRadius * 2,
    [theme.breakpoints.down("md")]: {
      minHeight: 200,
    },
  },
}));

export type FHSwiperSliderProps = {
  content?: any;
  pageImgQuality?: number;
  containerImgQuality?: number;
  isStoryBook?: boolean;
  imageLoader?: ImageLoader;
};

export const FHSwiperSlider: React.FC<FHSwiperSliderProps> = ({
  content,
  pageImgQuality,
  containerImgQuality,
  isStoryBook,
  imageLoader,
}: PropsWithChildren<FHSwiperSliderProps>) => {
  const theme = useTheme();
  const classes = useStyles();
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));
  const [isSwiperEnded, setIsSwiperEnded] = React.useState(false);

  const responsiveImage = (imageUrl: string) => {
    const image = useGenerateResponsiveImageUrl(
      imageUrl,
      defaultMaxWidths,
      undefined,
      true,
    );
    return image;
  };

  return (
    <div
      className={classnames(
        classes.root,
        {
          [classes.noFading]: mdUp ? content.length <= 3 : content.length <= 1,
        },
        { [classes.removeFading]: isSwiperEnded },
      )}
    >
      {content &&
        content.length > 0 &&
        ((
          <Swiper
            scrollbar={{
              hide: false,
              draggable: true,
            }}
            slidesPerView={mdUp ? 3 : 1}
            spaceBetween={mdUp ? 35 : 10}
            navigation={true}
            modules={[Navigation, Scrollbar]}
            className={classes.swiperSection}
            onSlideChange={(swiperCore) => {
              setIsSwiperEnded(swiperCore?.isEnd);
            }}
            onReachEnd={() => {
              setIsSwiperEnded(true);
            }}
          >
            {isStoryBook
              ? content?.map((item, index) => (
                  <SwiperSlide key={index}>
                    <div className={classes.slideImage}>
                      <FHNextImage
                        src={responsiveImage(item.url || "")}
                        alt={"title"}
                        layout="fill"
                        objectFit="cover"
                        loading="lazy"
                        loader={imageLoader}
                      />
                    </div>
                  </SwiperSlide>
                ))
              : content?.map((cards, i) => (
                  <SwiperSlide key={`swiper-slide-${i}`}>
                    <StandardCardComponent
                      {...cards?.attributes}
                      pageImgQuality={pageImgQuality}
                      containerImgQuality={containerImgQuality}
                      key={`standard-card-${i}`}
                    />
                  </SwiperSlide>
                ))}
          </Swiper>
        ) as any)}
    </div>
  );
};
export default FHSwiperSlider;
