import { makeStyles } from "@mui/styles";
import React from "react";
import { RichContentArea } from "../../components/SemanticTheme/RichContentArea";
import { makeHtml, processRichText } from "../../components/Utils";
import { Container, Grid } from "@mui/material";
import { withContent } from "../../utils/TemplateWithContent";
import { RichTextAreaEntity } from "src/graphql/generated-strapi/types";
import TwoColumnLayout from "@components/SereneTheme/TwoColumnLayout";
import CustomContainer from "@components/SereneTheme/CustomContainer";

const useStyles = (showPaddingTop: boolean, showPaddingBottom: boolean) =>
  makeStyles((theme) => ({
    richTextAreaRoot: {
      padding: theme.spacing(
        showPaddingTop ? 3.5 : 0,
        0,
        showPaddingBottom ? 3.5 : 0,
      ),
      "@media (max-width:600px)": {
        padding: theme.spacing(
          showPaddingTop ? 2.5 : 0,
          0,
          showPaddingBottom ? 2.5 : 0,
        ),
      },
      "& img": {
        maxWidth: "100%",
      },
    },
  }))();

const monthNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
interface AdditionalProps {
  additionalProps?: { [key in string]: string | boolean };
  isHomePage?: boolean;
  isGlentressLandingPageExp?: boolean;
}

const RichTextAreaComponent: React.FC<RichTextAreaEntity & AdditionalProps> = (
  props: RichTextAreaEntity & AdditionalProps,
): JSX.Element => {
  const isGlentressLandingPageExp = props?.isGlentressLandingPageExp;
  const readableDateFormat = props?.additionalProps?.readableDateFormat;
  const isHomePage = props?.isHomePage;
  const primaryContent = props?.attributes;
  const classes = useStyles(
    primaryContent?.ShowTopPadding || false,
    primaryContent?.ShowBottomPadding || false,
  );

  // FH_DateFormat_FR218
  const readableDateFormatExp =
    readableDateFormat &&
    isHomePage &&
    primaryContent?.MainText?.includes("Don&rsquo;t wait around to book");
  // FH_DateFormat_FR218
  // Function to update date format to DD MMM YYYY
  const updatedRichText = (richText?: string): string | undefined => {
    // Regex with capture groups to match DD/MM/YYYY
    const dateRegex = /(\d{2})\/(\d{2})\/(\d{4})/;
    // Check if richText is defined and has a date
    if (!richText || !dateRegex.test(richText)) {
      return richText; // Return unchanged if no text or no date
    }
    // Replace the date with the new format
    return richText?.replace(dateRegex, (_match, day, month, year) => {
      const monthAbbr = monthNames[parseInt(month) - 1]; // Convert month number to abbreviation
      return `${day} ${monthAbbr} ${year}`; // Format as DD MMM YYYY
    });
  };

  const expRichText = readableDateFormatExp
    ? updatedRichText(primaryContent?.MainText)
    : primaryContent?.MainText;

  const richText = readableDateFormatExp
    ? processRichText(expRichText)
    : processRichText(primaryContent?.MainText);

  return (
    <>
      {isGlentressLandingPageExp ? (
        <CustomContainer>
          <TwoColumnLayout
            spacing={2.4}
            leftColumnSize={7}
            leftColumn={
              <>
                <RichContentArea
                  styleH1AsH2={primaryContent?.StyleH1asH2 || false}
                  isGlentressLandingPageExp={isGlentressLandingPageExp}
                >
                  <div dangerouslySetInnerHTML={makeHtml(richText)} />
                </RichContentArea>
              </>
            }
            rightColumnSize={5}
          />
        </CustomContainer>
      ) : (
        <Container>
          <Grid className={classes.richTextAreaRoot}>
            <RichContentArea styleH1AsH2={primaryContent?.StyleH1asH2 || false}>
              <div dangerouslySetInnerHTML={makeHtml(richText)} />
            </RichContentArea>
          </Grid>
        </Container>
      )}
    </>
  );
};

const RichTextAreaComponentTemplate = (props) => {
  const content = props?.content?.textAreas?.data;
  const pageImgQuality = props?.pageImgQuality;
  const isHomePage = props?.isHomePage;
  const additionalProps = props?.additionalProps;
  const isGlentressLandingPageExp = props?.isGlentressLandingPageExp;

  return withContent(
    RichTextAreaComponent,
    content,
    undefined,
    pageImgQuality,
    additionalProps,
    undefined,
    isHomePage,
    isGlentressLandingPageExp,
  );
};
export default RichTextAreaComponentTemplate;
