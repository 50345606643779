import { ImageCard, ImageGallerySize } from "./index";
/**
 * Creates an array of booleans corresponding to the
 * index location of images param provided.
 *
 * The boolean value represents whether the image corresponding
 * to its index location should be applied a ratio or not.
 *
 * By default the smallest column value provided in the given row is chosen.
 *
 * The ratio location is given to one image per row and is chosen
 * either by having been given a preferred Ratio from the ImageCard's optional
 * property or the smallest column size in that particular row.
 *
 * e.g
 *
 * ImageCard[] = [{...md: 5}, {...md: 7}, {...md: 3}, {...md: 3}, {...md: 3}, {...md: 3}]
 * will be split to
 * [
 *  [{...md: 5}, {...md: 7}] // Total: 12
 *  [{...md: 3}, {...md: 3}, {...md: 3}, {...md: 3}] // Total: 12
 * ]
 *
 * which will finally return the value: [true, false, true, true, true, true]
 *
 * @param {ImageCard[]} images
 * @param {'md' | 'xs'} size
 * @param {size} defaultSize
 *
 * @returns {boolean[]} Returns an array of boolean that maps which image in
 * the corresponding index order should be applied a ratio to
 */
export const mapIndividualImagesInEachRowToHaveSpecifiedRatio = (
  images: ImageCard[],
  size: "md" | "xs",
  defaultSize: ImageGallerySize,
): boolean[] => {
  let cols = images.map(
    (image) => (size === "md" ? image.md : image.xs) || defaultSize,
  ) as number[];
  let totalRowColumns = cols.reduce((a, b) => a + b);

  let individualRows = new Array(Math.ceil(totalRowColumns / 12)).fill(
    [],
  ) as number[][];
  individualRows = individualRows.map((row: number[]) => {
    let totalColsInRow = 0;
    let rowIteration = 0;
    while (totalColsInRow < 12) {
      const numOfColumns = cols[rowIteration];
      if (
        typeof numOfColumns === "undefined" ||
        numOfColumns + totalColsInRow > 12
      ) {
        break;
      } else {
        rowIteration++;
        totalColsInRow += numOfColumns;
        row = [...row, numOfColumns];
      }
    }
    cols = cols.slice(rowIteration);
    return row;
  });

  let imageIteration = 0;
  return individualRows.flatMap((row, rowIndex) => {
    const min = Math.min(...row);
    const rowImages = images.slice(imageIteration, imageIteration + row.length);
    const hasRowImageCustomRatio = (rowImage: ImageCard) =>
      !!(size === "md"
        ? rowImage.preferredDesktopRatio
        : rowImage.preferredMobileRatio);
    const customRatioImagesInRow = rowImages.filter(hasRowImageCustomRatio);
    const hasCustomRatioInRow = customRatioImagesInRow.length > 0;
    if (customRatioImagesInRow.length > 1) {
      console.error(
        `${
          size === "md" ? '"preferredDesktopRatio"' : '"preferredMobileRatio"'
        } should only be applied to one image per row to prevent visual inconsistencies. Please check on row ${
          rowIndex + 1
        }. Only one of the following should have custom ratio: ${customRatioImagesInRow
          .map((rowImage, i) => `(${i + 1}) ${rowImage.altText}`)
          .join(", ")}`,
      );
    }
    imageIteration += row.length;
    return [
      ...(hasCustomRatioInRow
        ? rowImages.map(hasRowImageCustomRatio) // either return the one that has a custom ratio
        : row.map((columnAmount) => !!(columnAmount === min))), // or the one that has smallest column
    ];
  });
};
