import React, { PropsWithChildren, useEffect, useRef, useState } from "react";
import { makeStyles } from "@mui/styles";
import { CallToAction } from "../CallToAction";
import { Tile, TileProps } from "../Tile";
import Grid from "@mui/material/Grid";
import { GridSize } from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {
  ImageList,
  ImageListItem,
  Hidden,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { isBrowser } from "../../Utils";
import { TileContainerSecondaryDesign } from "./TileContainerSecondaryDesign";

export type TileContainerProps = {
  cards?: Array<typeof Tile | JSX.Element>;
  title?: string;
  subtitle?: string;
  ctaLink?: string;
  numberOfRowsMobile?: number;
  numberOfColumns?: GridSize;
  useSecondaryDesign?: boolean;
  linkText?: string;
  linkUrl?: string;
} & TileContainerStyleProps;

type TileContainerStyleProps = {
  maxWidth?: number | string;
  centered?: boolean;
};

const desktopTileSize = 200;

const calculateGridSizeFor = (numCols: number, tileSpacing: number) => {
  return (desktopTileSize + tileSpacing * 2) * numCols;
};

export const reorderMobileTilesIntoSeparateColumns = <T extends any>(
  numOfRows: number,
  cards: T[],
): Array<T[]> => {
  const cols = Math.ceil(cards.length / numOfRows);
  return new Array<T[]>(cols)
    .fill([])
    .map((_col, ci) => [
      ...new Array<T | null>(numOfRows)
        .fill(null)
        .map((_row, ri) => cards[cols * ri + ci]),
    ]);
};

const useStyles = (
  props: TileContainerStyleProps & {
    tileSpacing: number;
  },
  numCols?: number,
) =>
  makeStyles((theme) => ({
    root: {
      maxWidth: props.maxWidth,
      margin: props.centered ? "0 auto" : undefined,
    },
    title: {
      textAlign: "center",
      verticalAlign: "baseline",
      marginBottom: theme.spacing(1),
      padding: theme.spacing(2),
    },
    titleCallToAction: {
      color: theme.palette.text.primary,
    },
    mobileTileContainer: {
      display: "flex",
      flexWrap: "nowrap",
      left: -(props.tileSpacing / 2),
      transform: "translateZ(0)",
      padding: theme.spacing(
        props.tileSpacing / 10,
        props.tileSpacing / 10,
        2,
        props.tileSpacing / 10,
      ),
      position: "relative",
      width: "100%",
      margin: "0 !important",
    },
    mobileGridItem: {
      flexShrink: 0,
      "& .MuiGridListTile-tile > div:not(:last-child)": {
        marginBottom: props.tileSpacing,
      },
    },
    mobileCardContainer: {
      height: 0,
      paddingTop: "100%",
      overflow: "hidden",
      position: "relative",
      margin: theme.spacing(0.25, 0),
      "& > div": {
        position: "absolute",
        top: 0,
        width: "100%",
        height: "100%",
      },
    },
    desktopTileContainer: {
      width: "100%",
      position: "relative",
      margin: "0 auto",
      padding: theme.spacing(0, 0, 2, 0),
      maxWidth:
        typeof numCols !== "undefined"
          ? calculateGridSizeFor(numCols, props.tileSpacing) + "px"
          : undefined,
    },
    desktopGridItem: {
      margin: props.tileSpacing,
      minWidth: desktopTileSize,
      maxWidth: desktopTileSize,
      height: desktopTileSize,
    },
  }))();

export const TileContainer: React.FC<TileContainerProps> = ({
  cards = [],
  title = "",
  subtitle,
  ctaLink = "",
  numberOfColumns,
  numberOfRowsMobile = 4,
  maxWidth,
  centered = true,
  useSecondaryDesign = false,
  linkText,
  linkUrl = "",
}: PropsWithChildren<TileContainerProps>) => {
  const theme = useTheme();
  const mobileTiles = useRef<(React.FC<TileProps> | JSX.Element)[][]>([]);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [mobileViewTiles, setMobileViewTiles] = useState<
    (React.FC<TileProps> | JSX.Element)[][]
  >([]);
  const tileSpacing = isMobile ? 5 : 10;
  let gridSize: GridSize = "auto";
  let numColsValidated;
  const [browserMode, setBrowserMode] = React.useState(false);

  React.useEffect(() => {
    setBrowserMode(isBrowser());
  }, []);

  if (
    typeof numberOfColumns === "number" &&
    numberOfColumns > 1 &&
    numberOfColumns <= 12
  ) {
    gridSize = Math.ceil(12 / (numberOfColumns as number)) as GridSize;
    numColsValidated = numberOfColumns as number;
  }

  const classes = useStyles(
    {
      tileSpacing,
      maxWidth,
      centered,
    },
    numColsValidated,
  );

  useEffect(() => {
    if (cards.length) {
      numberOfRowsMobile = Math.ceil(cards.length / 3);
    }

    mobileTiles.current = reorderMobileTilesIntoSeparateColumns(
      numberOfRowsMobile,
      cards,
    );
  }, [numberOfRowsMobile, cards]);

  useEffect(() => {
    setMobileViewTiles(mobileTiles.current);
  }, [mobileTiles]);

  const getColumns = () => {
    const amountOfCards = mobileViewTiles.length;
    if (amountOfCards === 2) {
      return 2;
    } else if (amountOfCards > 2) {
      return 2.2;
    }
    return 1;
  };

  return (
    <>
      {useSecondaryDesign ? (
        <TileContainerSecondaryDesign
          cards={cards}
          linkText={linkText}
          linkUrl={linkUrl}
          title={title}
          subtitle={subtitle}
        />
      ) : (
        <div data-testid={"tileContainerRoot"}>
          <Grid
            container
            className={classes.title}
            alignItems="center"
            justifyContent="center"
          >
            {title && (
              <CallToAction
                callToAction={title}
                callToActionUrl={ctaLink}
                variant={"h2"}
                textClassName={classes.titleCallToAction}
                showUnderline={false}
                usePrimaryLightColor={false}
                showChevron={false}
              />
            )}
            {subtitle && (
              <Grid item xs={12}>
                <Typography variant={"body1"} align={"center"}>
                  {subtitle}
                </Typography>
              </Grid>
            )}
          </Grid>
          <Hidden smUp>
            <ImageList
              className={classes.mobileTileContainer}
              cols={mobileViewTiles.length > 1 ? 2 : 1}
              rowHeight={"auto"}
              gap={tileSpacing}
            >
              {mobileViewTiles?.map((column, i) => (
                <ImageListItem
                  key={i}
                  style={{ width: `${100 / getColumns()}%` }}
                  className={classes.mobileGridItem}
                >
                  <>
                    {column.map((card, tileNumber) => (
                      <div
                        className={classes.mobileCardContainer}
                        key={tileNumber}
                      >
                        <>{card}</>
                      </div>
                    ))}
                  </>
                </ImageListItem>
              ))}
            </ImageList>
          </Hidden>
          {browserMode ? (
            <Hidden xsDown>
              {!isMobile && (
                <div className={classes.desktopTileContainer}>
                  <Grid
                    container
                    spacing={2}
                    direction="row"
                    justifyContent="center"
                  >
                    {cards.map((card, i) => (
                      <Grid
                        key={i}
                        item
                        xs={gridSize}
                        className={classes.desktopGridItem}
                      >
                        <>{card}</>
                      </Grid>
                    ))}
                  </Grid>
                </div>
              )}
            </Hidden>
          ) : (
            <div className={classes.desktopTileContainer}>
              {!isMobile && (
                <div className={classes.desktopTileContainer}>
                  <Grid
                    container
                    spacing={2}
                    direction="row"
                    justifyContent="center"
                  >
                    {cards.map((card, i) => (
                      <Grid
                        key={i}
                        item
                        xs={gridSize}
                        className={classes.desktopGridItem}
                      >
                        <>{card}</>
                      </Grid>
                    ))}
                  </Grid>
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </>
  );
};
