import React, { PropsWithChildren, useEffect, useRef } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import { makeStyles } from "@mui/styles";
import Zoom from "@mui/material/Zoom";
import Modal from "@mui/material/Modal";

import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Close from "@mui/icons-material/Close";
import ZoomOutMapIcon from "@mui/icons-material/ZoomOutMap";
import { useTheme, Slide, Typography } from "@mui/material";
import classNames from "classnames";
import { FHNextImage, ImageLoader } from "../FHNextImage";
import { MemoizedVideo } from "../MemoizedVideo";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    position: "relative",
  },
  childrenWrapper: { height: "100%" },
  iconWrapper: {
    position: "absolute",
    left: "auto",
    bottom: 0,
    right: 0,
    marginLeft: "-50px",
    zIndex: 2,
  },
  buttonBackgroundColor: (props: any) => ({
    "& .MuiIconButton-root": {
      backgroundColor: props.buttonBackgroundColor,
      borderRadius: theme.spacing(0.5),
      padding: theme.spacing(0.25),
      margin: theme.spacing(1.25),
    },
  }),
  modal: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  paperContainer: {
    position: "fixed",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
  },
  paper: {
    position: "absolute",
    boxShadow: "none",
    top: "50%",
    left: "50%",
    transform: `translate(-50%, -50%)`,
    overflow: "hidden",
    "& img": {
      maxWidth: "100%",
      height: "auto",
      verticalAlign: "-webkit-baseline-middle",
    },
    minWidth: "100%",
    minHeight: "100%",
    maxWidth: "80%",
    maxHeight: "90%",
    opacity: 0,
    transition: "opacity 0.25s",
    "& span": {
      opacity: 0,
      "& img": {
        maxWidth: "90% !important",
        maxHeight: "90% !important",
        minWidth: "90% !important",
        minHeight: "90% !important",
      },
    },
  },
  paperVisible: {
    opacity: 1,
    "& span": {
      opacity: 1,
    },
  },
  loader: {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: `translate(-50%,0)`,
  },
  modalButtons: {
    zIndex: 1000,
    position: "absolute",
    transform: "translate(0, -50%)",
    "&.leftArrow": {
      top: "50%",
      left: 0,
    },
    "&.rightArrow": {
      top: "50%",
      right: 0,
    },
    "&.closeButton": {
      top: 25,
      right: 0,
    },
    "&.stepper": {
      bottom: 0,
      marginTop: -25,
      color: theme.palette.background.paper,
    },
  },
  backdropRoot: {
    background: "rgba(43, 43, 43, 0.5)",
  },
  largeCloseBtn: {
    "& svg": {
      fontSize: 35,
    },
  },
  videoContainer: {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%) !important",
    width: "70%",
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "95%",
    },
  },
  heroVideo: {
    width: "100%",
    backgroundSize: "cover",
    transition: "1s opacity",
    objectFit: "fill",
    maxHeight: 226,
    [theme.breakpoints.up(420)]: {
      width: 670,
      maxHeight: 408,
    },
  },
  darkBackdrop: {
    background: "#2B2B2BE5",
  },
}));

export type ModalImageProps = {
  imageUrl?: string;
  label?: string;
  altText?: string;
  buttonBackgroundColor?: string;
  videoUrl?: string;
  videoImageUrl?: string;
};

export type ModalImagesProps = {
  modalImages?: ModalImageProps[];
  position?: number;
  paginationHelperText?: string;
  children?: React.ReactNode;
  imageLoader?: ImageLoader;
  setOnClickImage?: () => void;
  clickedImagePosition?: null | number;
  isModalOpen?: boolean;
  onCloseModel?: (isClosed: boolean) => void;
  backdropDark?: boolean;
  isLargeCloseBtn?: boolean;
  videoInGallery?: boolean;
  videoPause?: () => void;
  currentVideoTime?: number;
};

type Props = ModalImagesProps & ModalImageProps;

export const ImageModalLink: React.FC<Props> = ({
  imageUrl,
  label,
  children,
  altText,
  modalImages,
  position = 0,
  buttonBackgroundColor = "rgba(0,0,0,0.4)",
  paginationHelperText = "/",
  imageLoader,
  setOnClickImage,
  clickedImagePosition,
  isModalOpen,
  onCloseModel,
  backdropDark,
  isLargeCloseBtn,
  videoInGallery,
  videoPause,
  currentVideoTime,
}: PropsWithChildren<Props>) => {
  const handleCloseTimerId = useRef<number>();
  const direction = useRef<"right" | "left">();
  const theme = useTheme();
  const video = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    updatePosition(position);
    return () => {
      // on unmount
      const timeoutId = handleCloseTimerId.current;
      if (timeoutId) {
        if (handleCloseTimerId) {
          handleCloseTimerId.current = undefined;
        }
        clearTimeout(timeoutId);
      }
    };
  }, [position]);

  useEffect(() => {
    // effect to handle onclick image - show gallery
    if (clickedImagePosition >= 0) {
      if (clickedImagePosition === position) {
        setOpen(true);
      }
    }
  }, [clickedImagePosition]);

  const classes = useStyles({ buttonBackgroundColor });
  const [open, setOpen] = React.useState(false);
  const [imgsLoaded, setImgsLoaded] = React.useState<boolean[]>(
    (modalImages || [{}]).map(() => false),
  );
  const [imgPosition, updatePosition] = React.useState(position);
  const responsiveImageUrl = imageUrl;
  let images = modalImages || [
    {
      imageUrl: responsiveImageUrl,
      label,
      altText,
    },
  ];
  const totalImages = images.length;

  useEffect(() => {
    setOpen(isModalOpen);
  }, [isModalOpen]);

  useEffect(() => {
    if (video?.current && videoInGallery) {
      video.current.currentTime = currentVideoTime;
      video.current.play();
    }
  }, [currentVideoTime]);

  const handlePositionRight = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ) => {
    e.stopPropagation();
    direction.current = "left";
    updatePosition(imgPosition === totalImages - 1 ? 0 : imgPosition + 1);
    video.current?.pause();
  };

  const handlePositionLeft = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ) => {
    e.stopPropagation();
    direction.current = "right";
    updatePosition(imgPosition === 0 ? totalImages - 1 : imgPosition - 1);
    video.current?.pause();
  };

  const handleClose = () => {
    const timeoutId = handleCloseTimerId.current;
    if (timeoutId && handleCloseTimerId) {
      handleCloseTimerId.current = undefined;
      clearTimeout(timeoutId);
    }
    setImgsLoaded(images.map(() => false));
    if (typeof window !== "undefined") {
      handleCloseTimerId.current = window.setTimeout(() => {
        setOpen(false);
        onCloseModel && onCloseModel(false);
        setOnClickImage && setOnClickImage();
        updatePosition(position); // back to initial position;
      }, 251);
    }
  };

  return (
    <div className={classes.root} data-testid="root">
      <div className={classes.childrenWrapper}>{children}</div>
      <div className={classes.iconWrapper}>
        <label
          htmlFor="icon-button-file"
          className={`imagePreviewIcon ${classes.buttonBackgroundColor}`}
        >
          <IconButton
            aria-label="Full Screen"
            component="span"
            onClick={() => {
              setOpen(true);
              videoPause && videoPause();
            }}
            data-testid="open-button"
          >
            <ZoomOutMapIcon style={{ color: theme.palette.background.paper }} />
          </IconButton>
        </label>
      </div>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        data-testid="modal"
        BackdropProps={{ "data-testid": "modal-backdrop" } as any}
        classes={{
          backdrop:
            backdropDark || videoInGallery
              ? backdropDark
                ? classes.backdropRoot
                : videoInGallery
                  ? classes.darkBackdrop
                  : ""
              : "",
        }}
      >
        <div className={classes.modal} data-testid="modal-body">
          <label
            htmlFor="icon-button-file"
            className={`${classes.modalButtons} closeButton`}
          >
            <IconButton
              color="primary"
              aria-label={label}
              component="span"
              onClick={handleClose}
              data-testid="open-button"
              className={classNames({
                [classes.largeCloseBtn]: isLargeCloseBtn,
              })}
            >
              <Close
                fontSize="small"
                style={{ color: theme.palette.background.paper }}
              />
            </IconButton>
          </label>
          {totalImages > 1 && (
            <>
              <label
                htmlFor="icon-button-file"
                className={`${classes.modalButtons} leftArrow`}
              >
                <IconButton
                  color="primary"
                  aria-label={label}
                  component="span"
                  onClick={handlePositionLeft}
                  data-testid="open-button"
                >
                  <ArrowBackIosNewIcon
                    fontSize="small"
                    style={{
                      color: theme.palette.background.paper,
                    }}
                  />
                </IconButton>
              </label>
              <label
                htmlFor="icon-button-file"
                className={`${classes.modalButtons} rightArrow`}
              >
                <IconButton
                  color="primary"
                  aria-label={label}
                  component="span"
                  onClick={handlePositionRight}
                  data-testid="open-button"
                >
                  <ArrowForwardIosIcon
                    fontSize="small"
                    style={{
                      color: theme.palette.background.paper,
                    }}
                  />
                </IconButton>
              </label>
              <Typography className={`${classes.modalButtons} stepper`}>
                {imgPosition + 1} {paginationHelperText} {totalImages}
              </Typography>
            </>
          )}

          {videoInGallery ? (
            <div className={classes.paperContainer}>
              {images.map((image, i) => (
                <Slide
                  direction={
                    imgPosition === i
                      ? direction.current
                      : direction.current === "left"
                        ? "right"
                        : "left"
                  }
                  in={imgPosition === i}
                  key={`img_pos_${i}`}
                >
                  {!!image?.videoUrl ? (
                    <div className={classes.videoContainer}>
                      <MemoizedVideo
                        {...{
                          src: image?.videoUrl,
                          autoPlay: false,
                          className: classes.heroVideo,
                          innerRef: video,
                          controls: true,
                          muted: false,
                          poster: image?.videoImageUrl,
                        }}
                      />
                    </div>
                  ) : (
                    <div className={classes.paperContainer}>
                      <Zoom
                        in={imgsLoaded[i]}
                        style={{
                          transitionDelay: imgsLoaded[i] ? "250ms" : "0ms",
                        }}
                        data-testid="zoom"
                      >
                        <div className={classes.paperContainer}>
                          <div
                            className={classNames(classes.paper, {
                              [classes.paperVisible]:
                                imgsLoaded[i] && imgPosition === i,
                            })}
                          >
                            <FHNextImage
                              src={image.imageUrl}
                              alt={image.altText}
                              layout="fill"
                              objectFit="contain"
                              loading="lazy"
                              onLoad={() =>
                                setImgsLoaded(
                                  imgsLoaded.map(
                                    (img, imgIteration) =>
                                      imgIteration === i || img || true,
                                  ),
                                )
                              }
                              loader={imageLoader}
                              data-testid="image"
                            />
                          </div>
                        </div>
                      </Zoom>
                      {!!direction.current && !imgsLoaded[i] && (
                        <div className={classes.loader}>
                          <CircularProgress />
                        </div>
                      )}
                    </div>
                  )}
                </Slide>
              ))}
            </div>
          ) : (
            <>
              {images.map((image, i) => (
                <div className={classes.paperContainer} key={`img_pos_${i}`}>
                  <Slide
                    direction={
                      imgPosition === i
                        ? direction.current
                        : direction.current === "left"
                          ? "right"
                          : "left"
                    }
                    in={imgPosition === i}
                  >
                    <div className={classes.paperContainer}>
                      <Zoom
                        in={imgsLoaded[i]}
                        style={{
                          transitionDelay: imgsLoaded[i] ? "250ms" : "0ms",
                        }}
                        data-testid="zoom"
                      >
                        <div className={classes.paperContainer}>
                          <div
                            className={classNames(classes.paper, {
                              [classes.paperVisible]:
                                imgsLoaded[i] && imgPosition === i,
                            })}
                          >
                            <FHNextImage
                              src={image.imageUrl}
                              alt={image.altText}
                              layout="fill"
                              objectFit="contain"
                              loading="lazy"
                              onLoad={() =>
                                setImgsLoaded(
                                  imgsLoaded.map(
                                    (img, imgIteration) =>
                                      imgIteration === i || img || true,
                                  ),
                                )
                              }
                              loader={imageLoader}
                              data-testid="image"
                            />
                          </div>
                        </div>
                      </Zoom>
                      {!!direction.current && !imgsLoaded[i] && (
                        <div className={classes.loader}>
                          <CircularProgress />
                        </div>
                      )}
                    </div>
                  </Slide>
                </div>
              ))}
            </>
          )}
          {!direction.current && !imgsLoaded[imgPosition] && (
            <div className={classes.loader}>
              <CircularProgress />
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
};
