import { Container, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import FHButton from "../../components/SemanticTheme/FHButton";
import {
  updateAbsoluteLink,
  defaultTileWidths,
  useGenerateResponsiveImageUrl,
} from "../../components/Utils";
import { TileContainer } from "../../components/SemanticTheme/TileContainer";
import { Tile } from "../../components/SemanticTheme/Tile";
import { withContent } from "../../utils/TemplateWithContent";
import { TileContainerEntity } from "src/graphql/generated-strapi/types";

type TileContainerComponent = {
  pageImgQuality?: number;
} & TileContainerEntity;

type SecondaryDesignProps = {
  isSecondaryDesign: boolean;
  linkText: string;
  linkUrl: string;
};

const TileContainerComponent: React.FC<TileContainerComponent> = (
  props: TileContainerComponent,
): JSX.Element => {
  const { attributes: cmsData, pageImgQuality } = props;
  const cardsContentValue = cmsData?.Tiles;
  const containerTitle = cmsData?.ContainerTitleText as string;
  const containerSubTitle = cmsData?.ContainerSubtitleText as string;
  const callToActionText = cmsData?.CallToActionText as string;
  const callToActionUrl = cmsData?.CallToActionLink?.data?.attributes?.PageRoute
    ? updateAbsoluteLink(cmsData?.CallToActionLink?.data?.attributes?.PageRoute)
    : undefined;
  const imgQuality = cmsData?.ImageQuality || pageImgQuality;
  const [secondaryDesignProps, setSecondaryDesignProps] =
    useState<SecondaryDesignProps>({
      isSecondaryDesign: false,
      linkText: "",
      linkUrl: "",
    });

  // Expand the contents of the 'Cards' Property to an array of ThingsToDoCards props
  const expandedValues = cardsContentValue?.data.map((contentItem) => {
    const image = contentItem?.attributes?.Image;
    const imageUrl = image
      ? useGenerateResponsiveImageUrl(
          image?.data?.attributes?.url,
          defaultTileWidths,
          contentItem?.attributes?.ImageQuality || imgQuality,
          true,
        )
      : undefined;
    const link = contentItem?.attributes?.Link;
    const linkUrl = link
      ? updateAbsoluteLink(link?.data?.attributes?.PageRoute)
      : undefined;

    useEffect(() => {
      const useSecondaryDesign = cmsData?.UseSecondaryDesign || false;

      if (useSecondaryDesign) {
        const secondaryDesignLinkText = cmsData?.LinkText as string;
        const secondaryDesignLinkUrl = cmsData?.LinkUrl?.data?.attributes
          ?.PageRoute
          ? updateAbsoluteLink(cmsData?.LinkUrl?.data?.attributes?.PageRoute)
          : undefined;
        setSecondaryDesignProps({
          isSecondaryDesign: useSecondaryDesign,
          linkText: secondaryDesignLinkText,
          linkUrl: secondaryDesignLinkUrl,
        });
      }
    }, []);

    return {
      backgroundImageUrl: imageUrl,
      title: contentItem?.attributes?.Title as string,
      subtitle: contentItem?.attributes?.SubTitle as string,
      description: contentItem?.attributes?.Description as string,
      chip: contentItem?.attributes?.Tag as string,
      link: linkUrl,
      responsive: contentItem?.attributes?.Responsive as boolean,
    };
  });

  // Map the props to an array of card components
  const cards =
    expandedValues &&
    expandedValues.map((card, i) => (
      <Tile
        key={i}
        backgroundImageUrl={card.backgroundImageUrl}
        title={card.title}
        subtitle={card.subtitle}
        description={card.description}
        chip={card.chip}
        link={card.link}
        responsive={card.responsive}
        useSecondaryDesign={secondaryDesignProps?.isSecondaryDesign}
      />
    ));

  return (
    <Container>
      <TileContainer
        cards={cards}
        title={containerTitle}
        subtitle={containerSubTitle}
        numberOfColumns={8}
        ctaLink={callToActionUrl}
        useSecondaryDesign={secondaryDesignProps?.isSecondaryDesign}
        linkText={secondaryDesignProps?.linkText}
        linkUrl={secondaryDesignProps?.linkUrl}
      />
      {callToActionText &&
        callToActionUrl &&
        !secondaryDesignProps?.isSecondaryDesign && (
          <Grid textAlign={"center"}>
            <FHButton
              href={callToActionUrl}
              fhStyle={"secondary"}
              fullWidth={false}
              minWidth={"350px"}
            >
              {callToActionText}
            </FHButton>
          </Grid>
        )}
    </Container>
  );
};
const TileContainerComponentTemplate = (props) => {
  const content = props?.content?.tiles?.data;
  const pageImgQuality = props?.pageImgQuality;
  return withContent(
    TileContainerComponent,
    content,
    undefined,
    pageImgQuality,
  );
};
export default TileContainerComponentTemplate;
