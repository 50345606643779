import React, { PropsWithChildren, useEffect, useRef, useState } from "react";
import { makeStyles } from "@mui/styles";
import { IconButton } from "@mui/material";
import Pause from "@mui/icons-material/Pause";
import Play from "@mui/icons-material/PlayArrow";
import { useOnScreen } from "../../../hooks/hooks";
import classNames from "classnames";

const useStyles = () =>
  makeStyles((theme) => ({
    root: {
      position: "relative",
      height: "100%",
    },
    internalMedia: {
      width: "100%",
      height: "100%",
      backgroundSize: "cover",
      transition: "1s opacity",
      objectFit: "fill",
    },
    playPauseButton: {
      border: `2px solid ${theme.palette.background.paper}`,
      padding: theme.spacing(0.5),
      color: theme.palette.background.paper,
      width: 62,
      height: 62,
      [theme.breakpoints.up("sm")]: {
        border: `4px solid ${theme.palette.background.paper}`,
        width: 100,
        height: 100,
      },
      "& svg": {
        fontSize: 44,
        [theme.breakpoints.up("sm")]: {
          fontSize: 70,
        },
      },
    },
    videoOverlay: {
      width: "100%",
      height: "100%",
      position: "absolute",
      left: 0,
      top: 0,
      zIndex: 1,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      "& $playPauseButton": {
        border: `2px solid ${theme.palette.background.paper}`,
        padding: theme.spacing(0.5),
        color: theme.palette.background.paper,
        width: 62,
        height: 62,
        [theme.breakpoints.up("sm")]: {
          border: `4px solid ${theme.palette.background.paper}`,
          width: 100,
          height: 100,
        },
        "& svg": {
          fontSize: 44,
          [theme.breakpoints.up("sm")]: {
            fontSize: 70,
          },
        },
      },
    },
    videoPlaying: {
      "& $playPauseButton": {
        opacity: 0,
      },
      "&:hover": {
        "& $playPauseButton": {
          opacity: 1,
        },
      },
    },
  }))();

interface ImageGalleryVideoBlockProps {
  videoUrl: string;
  videoPoster?: string;
  autoplay?: boolean;
  isPausedFromParent?: boolean;
  onPauseStateChange?: (paused: boolean) => void;
  onCurrentTimeChange?: (currentTime: number) => void;
}

export const ImageGalleryVideoBlock: React.FC<
  React.PropsWithChildren<ImageGalleryVideoBlockProps>
> = ({
  videoUrl,
  videoPoster,
  autoplay = false,
  isPausedFromParent = !autoplay, // Default to paused unless autoplay is true
  onPauseStateChange,
  onCurrentTimeChange,
}) => {
  const [videoPaused, setVideoPaused] = useState<boolean>(isPausedFromParent);
  const classes = useStyles();
  const video = useRef<HTMLVideoElement>(null);
  const isMP4Visible = useOnScreen(video);

  // Handle playback based on parent state, visibility, and autoplay
  useEffect(() => {
    if (!video.current) return;

    if (isPausedFromParent || !isMP4Visible) {
      // Pause if parent says so or video is out of viewport
      if (!videoPaused) {
        video.current.pause();
        setVideoPaused(true);
        onPauseStateChange?.(true);
        const currentTime = video.current.currentTime;
        onCurrentTimeChange?.(currentTime);
      }
    } else if (!isPausedFromParent && isMP4Visible && autoplay && videoPaused) {
      // Play if parent allows, video is visible, and autoplay is enabled
      video.current.play();
      setVideoPaused(false);
      onPauseStateChange?.(false);
    }
  }, [
    isPausedFromParent,
    isMP4Visible,
    autoplay,
    videoPaused,
    onPauseStateChange,
    onCurrentTimeChange,
  ]);

  const handlePlayPause = () => {
    if (!video.current) return;

    if (videoPaused) {
      video.current.play();
      setVideoPaused(false);
      onPauseStateChange?.(false);
    } else {
      video.current.pause();
      setVideoPaused(true);
      onPauseStateChange?.(true);
      const currentTime = video.current.currentTime;
      onCurrentTimeChange?.(currentTime);
    }
  };

  // Handle video completion
  const handleVideoEnded = () => {
    if (video.current) {
      setVideoPaused(true); // Show play icon when video ends
      onPauseStateChange?.(true);
      const currentTime = video.current.currentTime;
      onCurrentTimeChange?.(currentTime);
    }
  };

  return (
    <div
      className={classNames(classes.root, {
        [classes.videoPlaying]: !videoPaused,
      })}
    >
      <video
        src={videoUrl}
        className={classes.internalMedia}
        ref={video}
        playsInline={autoplay}
        data-testid="imageGalleryVideo"
        poster={videoPoster ? videoPoster : undefined}
        onEnded={handleVideoEnded} // handler for video end
      />
      <div className={classes.videoOverlay}>
        <IconButton
          className={classes.playPauseButton}
          onClick={handlePlayPause}
        >
          {videoPaused ? <Play /> : <Pause />}
        </IconButton>
      </div>
    </div>
  );
};
